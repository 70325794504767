(function (root, factory) {
    // Based on the standard UMD jQuery template
    // https://github.com/umdjs/umd/blob/master/templates/jqueryPlugin.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        var jQuery = require('jquery');
        module.exports = factory(jQuery);
    } else {
        factory(root.jQuery);
    }
    }(this, function($) {
    $.fn.jqt = function jqt(opts) {
        opts = $.extend({
        display: 'block',   
        enter: 'enter',
        enterActive: 'enter-active',
        leave: 'leave',
        leaveActive: 'leave-active',
        delay: 0,
        speed: 200
        }, opts);

        var el = this;

        return {
        enter: function(cb) {
            setTimeout(function() {
            el.addClass(opts.enter)
                .css('display', opts.display);

            setTimeout(function() {
                el.addClass(opts.enterActive);
            }, 50);

            setTimeout(function() {
                el.removeClass(opts.enter)
                .removeClass(opts.enterActive);

                cb && cb();
            }, opts.speed);
            }, opts.delay);
        },

        exit: function(cb) {
            setTimeout(function() {
            el.addClass(opts.leave);

            setTimeout(function() {
                el.addClass(opts.leaveActive);
            }, 50);

            setTimeout(function() {
                el.css('display', 'none')
                .removeClass(opts.leave)
                .removeClass(opts.leaveActive);

                cb && cb();
            }, opts.speed);
            }, opts.delay);
        }
        }
    }
}));

var links = $('.js-section-2-tabs div');
var tabs = $('.js-section-2-content');
var active = 0;

links.on('click', function(evt) {
  evt.preventDefault();

  var $this = $(this);
  var index = $this.data('index');

  // So we don't cause a weird transitioning of the same element
  if ( active === index ) {
    return;
  }

  // Remove 'active' class from the currently active tab button
  // Transition out the currently active tab body
  $(links[active]).removeClass('active');
  $(tabs[active]).jqt().exit();

  // Add the 'active' class to the next active tab button (clicked button)
  // Transition in the next active tab body
  $this.addClass('active');
  $(tabs[index]).jqt({ delay: 200, display: 'flex' }).enter();

  // Update active tab
  active = index;
});