var swiper_fs = new Swiper(".fs-slider", {
    speed: 1000,
    autoplay: {
        delay: 2000,
    },
    breakpoints: {
        320: {
            autoHeight: true,
        },
        768: {
            autoHeight: false,
        }
    },
    pagination: {
        clickable: true,
        el: '.swiper-pagination', 
        type: 'bullets',
    },
});
 
var swiper_adv = new Swiper(".advantages-slider", {
    effect: "fade",
    speed: 1000,
    navigation: {
        clickable: true,
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

var swiper_images = new Swiper(".images", {
    speed: 1000,
    autoplay: {
        delay: 2500,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        clickable: true,
        el: '.swiper-pagination',
        type: 'bullets',
    },
});